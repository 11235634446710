
const Modal = ({ open, onClose, children }) => {
  return (
    // backdrop
    <div
      onClick={() => console.log("click backdrop")}
      className={`
        fixed inset-0 flex justify-center items-center transition-colors z-10
        ${open ? "visible bg-gray-300 bg-opacity-50" : "hidden"}
      `}
    >
      {/* modal */}
      <div
        onClick={(e) => e.stopPropagation()}
        className={`
          bg-white rounded-xl shadow transition-all w-1/2 h-auto
          ${open ? "scale-100 opacity-100" : "scale-125 opacity-0"}
        `}
      >
        {/* <button onClick={onClose} className="absolute top-2 right-2 p-1 rounded-lg text-gray-400 bg-white hover:bg-gray-50 hover:text-gray-600">
          Close
        </button> */}
        {children}
      </div>
    </div>
  )
}

export default Modal;