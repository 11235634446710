const appConfig = {
  apiPrefix: process.env.NODE_ENV === "production" ? "http://www.vcode.site" : "http://localhost:8080",
  authenticatedEntryPath: "/app/main/dashboard",
  unAuthenticatedEntryPath: "/sign-in",
  tourPath: "/",
  locale: "en",
  enableMock: false,
};

export default appConfig;
