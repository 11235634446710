import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Api from "services/api/Api";
import httpErrorHandler from "services/api/httpErrorHandler";

export const getProducts = createAsyncThunk(
  "product/getProducts",
  async (_, { rejectWithValue, fulfillWithValue }) => {
    try {
      const { data } = await Api.get(`/products`);
      console.log("getProducts", data.data);
      return fulfillWithValue(data);
    } catch (error) {
      // console.log(error.response.data);
      const errMsg = httpErrorHandler(error);
      return rejectWithValue(errMsg);
    }
  }
);

export const createProduct = createAsyncThunk(
  "product/createProduct",
  async (info, { rejectWithValue, fulfillWithValue }) => {
    console.log("info", info);
    try {
      const { data } = await Api.post(`/products`, info);
      console.log("createProduct", data);
      return fulfillWithValue(data);
    } catch (error) {
      console.log("createProduct error", error.response.data);
      const errMsg = httpErrorHandler(error);
      console.log("createProduct errMsg", errMsg);
      return rejectWithValue(error?.response?.data ?? JSON.stringify(error));
    }
  }
);

export const updateProduct = createAsyncThunk(
  "product/updateProduct",
  async (info, { rejectWithValue, fulfillWithValue }) => {
    console.log("info", info);
    const { id } = info;
    try {
      const { data } = await Api.patch(`/products/${id}`, info);
      console.log("updateProduct", data);
      return fulfillWithValue(data);
    } catch (error) {
      console.log("updateProduct error", error.response.data);
      const errMsg = httpErrorHandler(error);
      console.log("updateProduct errMsg", errMsg);
      return rejectWithValue(error?.response?.data ?? JSON.stringify(error));
    }
  }
);

const productSlice = createSlice({
  name: "product",
  initialState: {
    successMessage: "",
    errorMessage: "",
    loader: false,
    products: [],
    selectedProduct: {},
  },
  reducers: {
    messageClear: (state, _) => {
      state.errorMessage = "";
      state.successMessage = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getProducts.pending, (state, { payload }) => {
        state.loader = true;
      })
      .addCase(getProducts.rejected, (state, { payload }) => {
        state.loader = false;
        state.errorMessage = payload.error;
      })
      .addCase(getProducts.fulfilled, (state, { payload }) => {
        state.loader = false;
        // state.successMessage = payload.message;
        state.products = payload.data;
      })
      .addCase(createProduct.pending, (state) => {
        state.loading = true;
      })
      .addCase(createProduct.rejected, (state, { payload }) => {
        state.loading = false;
        state.errorMessage = payload.error;
      })
      .addCase(createProduct.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.successMessage = payload.message;
        state.selectedProduct = payload.data;
      })
      .addCase(updateProduct.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateProduct.rejected, (state, { payload }) => {
        console.log("payload", payload);
        state.loading = false;
        state.errorMessage = payload.error;
      })
      .addCase(updateProduct.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.successMessage = payload.message;
        state.selectedProduct = payload.data;
      });
  },
});

export const { messageClear } = productSlice.actions;
export default productSlice.reducer;
