import { combineReducers } from '@reduxjs/toolkit'
// import session from './sessionSlice'
import user from './userSlice'

const authReducer = combineReducers({
    // session,
    user,
})

export default authReducer
