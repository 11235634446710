import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Api from "services/api/Api";
import httpErrorHandler from "services/api/httpErrorHandler";

export const getDeliveryOrders = createAsyncThunk(
  "deliveryOrder/getDeliveryOrders",
  async (_, { rejectWithValue, fulfillWithValue }) => {
    try {
      const { data } = await Api.get("/deliveryorders");
      console.log("getDeliveryOrders", data);
      return fulfillWithValue(data);
    } catch (error) {
      console.log("getDeliveryOrders error", error.response.data);
      const errMsg = httpErrorHandler(error);
      console.log("getDeliveryOrders errMsg", errMsg);
      return rejectWithValue(error?.response?.data ?? JSON.stringify(error));
    }
  }
);

export const createDeliveryOrder = createAsyncThunk(
  "deliveryOrder/createDeliveryOrder",
  async (info, { rejectWithValue, fulfillWithValue }) => {
    console.log("info", info);
    try {
      const { data } = await Api.post(`/deliveryorders`, info);
      console.log("createDeliveryOrder", data);
      return fulfillWithValue(data);
    } catch (error) {
      console.log("createDeliveryOrder error", error.response.data);
      const errMsg = httpErrorHandler(error);
      console.log("createDeliveryOrder errMsg", errMsg);
      return rejectWithValue(error?.response?.data ?? JSON.stringify(error));
    }
  }
);

export const getDeliveryOrderByDoNum = createAsyncThunk(
  "deliveryOrder/getDeliveryOrderByDoNum",
  async (info, { rejectWithValue, fulfillWithValue }) => {
    try {
      const { data } = await Api.get("/deliveryorders/" + info.doNumber);
      console.log("getDeliveryOrderByDoNum", data);
      return fulfillWithValue(data);
    } catch (error) {
      console.log("getDeliveryOrderByDoNum error", error.response.data);
      const errMsg = httpErrorHandler(error);
      console.log("getDeliveryOrderByDoNum errMsg", errMsg);
      return rejectWithValue(error?.response?.data ?? JSON.stringify(error));
    }
  }
);

// export const getUserScanHistory = createAsyncThunk(
//   "deliveryOrder/getUserScanHistory",
//   async (info, { rejectWithValue, fulfillWithValue }) => {
//     try {
//       const { data } = await Api.get("/users/scan/" + info.id);
//       console.log("getUserScanHistory", data);
//       return fulfillWithValue(data);
//     } catch (error) {
//       console.log("getUserScanHistory error", error.response.data);
//       const errMsg = httpErrorHandler(error);
//       console.log("getUserScanHistory errMsg", errMsg);
//       return rejectWithValue(error?.response?.data ?? JSON.stringify(error));
//     }
//   }
// );

// export const updateUser = createAsyncThunk(
//   "deliveryOrder/updateUser",
//   async (info, { rejectWithValue, fulfillWithValue }) => {
//     console.log("info", info);
//     const { id } = info;
//     try {
//       const { data } = await Api.patch(`/users/${id}`, info);
//       console.log("updateUser", data);
//       return fulfillWithValue(data);
//     } catch (error) {
//       console.log("updateUser error", error.response.data);
//       const errMsg = httpErrorHandler(error);
//       console.log("updateUser errMsg", errMsg);
//       return rejectWithValue(error?.response?.data ?? JSON.stringify(error));
//     }
//   }
// );

const deliveryOrderSlice = createSlice({
  name: "deliveryOrder",
  initialState: {
    successMessage: "",
    errorMessage: "",
    saveDoSuccessMessage: "",
    saveDoErrorMessage: "",
    loading: false,
    selectedDO: {},
    deliveryOrderList: [],
    total: 0,
  },
  reducers: {
    messageClear: (state, _) => {
      state.errorMessage = "";
      state.successMessage = "";
      state.saveDoSuccessMessage = "";
      state.saveDoErrorMessage = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getDeliveryOrders.pending, (state) => {
        state.loading = true;
      })
      .addCase(getDeliveryOrders.rejected, (state, { payload }) => {
        state.loading = false;
        // state.errorMessage = payload.error;
      })
      .addCase(getDeliveryOrders.fulfilled, (state, { payload }) => {
        state.loading = false;
        // state.successMessage = payload.message;
        state.total = payload.total;
        state.deliveryOrderList = payload.data;
      })
      .addCase(createDeliveryOrder.pending, (state) => {
        state.loading = true;
      })
      .addCase(createDeliveryOrder.rejected, (state, { payload }) => {
        state.loading = false;
        state.saveDoErrorMessage = payload.error;
      })
      .addCase(createDeliveryOrder.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.saveDoSuccessMessage = payload.message;
        state.selectedDO = payload.data;
      })
      .addCase(getDeliveryOrderByDoNum.pending, (state) => {
        state.loading = true;
      })
      .addCase(getDeliveryOrderByDoNum.rejected, (state, { payload }) => {
        state.loading = false;
        // state.errorMessage = payload.error;
      })
      .addCase(getDeliveryOrderByDoNum.fulfilled, (state, { payload }) => {
        state.loading = false;
        // state.successMessage = payload.message;
        state.selectedDO = payload.data;
      })
      // .addCase(getUserScanHistory.pending, (state) => {
      //   state.loading = true;
      // })
      // .addCase(getUserScanHistory.rejected, (state, { payload }) => {
      //   state.loading = false;
      //   // state.errorMessage = payload.error;
      // })
      // .addCase(getUserScanHistory.fulfilled, (state, { payload }) => {
      //   state.loading = false;
      //   // state.successMessage = payload.message;
      //   state.scanHistory = payload.data;
      //   state.total = payload.total;
      // })

      // .addCase(updateUser.pending, (state) => {
      //   state.loading = true;
      // })
      // .addCase(updateUser.rejected, (state, { payload }) => {
      //   console.log("payload", payload);
      //   state.loading = false;
      //   state.errorMessage = payload.error;
      // })
      // .addCase(updateUser.fulfilled, (state, { payload }) => {
      //   state.loading = false;
      //   state.successMessage = payload.message;
      //   state.selectedDO = payload.data;
      // });
  },
});

export const { messageClear } = deliveryOrderSlice.actions;
export default deliveryOrderSlice.reducer;