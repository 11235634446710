import React from "react";
import { Notification, toast } from "components/ui";

const openNotification = (type, title = "", message = "") => {
  toast.push(
    <Notification title={title} type={type} closable>
      {message}
    </Notification>,
    {
      placement: 'top-end',
    }
  );
};

export default openNotification;
