import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
// import { addCompany } from "store/thunks/company/addCompany";
import Api from "services/api/Api";
import httpErrorHandler from "services/api/httpErrorHandler";

export const getCompanies = createAsyncThunk(
  "company/getCompanies",
  async (_, { rejectWithValue, fulfillWithValue }) => {
    try {
      const { data } = await Api.get("/companies");
      console.log("getCompanies", data);
      return fulfillWithValue(data);
    } catch (error) {
      console.log("addCompany error", error.response.data);
      const errMsg = httpErrorHandler(error);
      console.log("addCompany errMsg", errMsg);
      return rejectWithValue(errMsg);
    }
  }
);

export const getSubCompany = createAsyncThunk(
  "company/getSubCompany",
  async (info, { rejectWithValue, fulfillWithValue }) => {
    console.log("info", info);
    const { id } = info;
    try {
      const { data } = await Api.get(`/companies/sub/${id}`);
      console.log("getSubCompany", data);
      return fulfillWithValue(data);
    } catch (error) {getSubCompany
      console.log("getSubCompany error", error.response.data);
      const errMsg = httpErrorHandler(error);
      console.log("getSubCompany errMsg", errMsg);
      return rejectWithValue(errMsg);
    }
  }
);

export const getCompanyById = createAsyncThunk(
  "company/getCompanyById",
  async (info, { rejectWithValue, fulfillWithValue }) => {
    try {
      const { data } = await Api.get("/companies/" + info.id);
      console.log("getCompanyById", data);
      return fulfillWithValue(data);
    } catch (error) {
      console.log("addCompany error", error.response.data);
      const errMsg = httpErrorHandler(error);
      console.log("addCompany errMsg", errMsg);
      return rejectWithValue(errMsg);
    }
  }
);

export const addCompany = createAsyncThunk(
  "company/addCompany",
  async (info, { rejectWithValue, fulfillWithValue }) => {
    console.log("info", info);
    try {
      const { data } = await Api.post(`/companies`, info);
      console.log("addCompany", data);
      return fulfillWithValue(data);
    } catch (error) {
      console.log("addCompany error", error.response.data);
      const errMsg = httpErrorHandler(error);
      console.log("addCompany errMsg", errMsg);
      return rejectWithValue(errMsg);
    }
  }
);

export const addRetailer= createAsyncThunk(
  "company/addRetailer",
  async (info, { rejectWithValue, fulfillWithValue }) => {
    console.log("info", info);
    try {
      const { data } = await Api.post(`/companies/retailer`, info);
      console.log("addRetailer", data);
      return fulfillWithValue(data);
    } catch (error) {
      console.log("addRetailer error", error.response.data);
      const errMsg = httpErrorHandler(error);
      console.log("addRetailer errMsg", errMsg);
      return rejectWithValue(errMsg);
    }
  }
);

export const updateCompany = createAsyncThunk(
  "company/updateCompany",
  async (info, { rejectWithValue, fulfillWithValue }) => {
    console.log("info", info);
    const { id } = info;
    try {
      const { data } = await Api.patch(`/companies/${id}`, info);
      console.log("updateCompany", data);
      return fulfillWithValue(data);
    } catch (error) {
      console.log("updateCompany error", error.response.data);
      const errMsg = httpErrorHandler(error);
      console.log("updateCompany errMsg", errMsg);
      return rejectWithValue(errMsg);
    }
  }
);

const companySlice = createSlice({
  name: "company",
  initialState: {
    successMessage: "",
    errorMessage: "",
    loader: false,
    selectedCompany: {},
    companyList: [],
    subCompanyList: [],
    total: 0,
  },
  reducers: {
    messageClear: (state, _) => {
      state.errorMessage = "";
      state.successMessage = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCompanies.pending, (state) => {
        state.loading = true;
      })
      .addCase(getCompanies.rejected, (state, { payload }) => {
        state.loading = false;
        // state.error = payload.error;
      })
      .addCase(getCompanies.fulfilled, (state, { payload }) => {
        state.loading = false;
        // state.successMessage = payload.message;
        state.total = payload.total;
        state.companyList = payload.data;
      })
      .addCase(getSubCompany.pending, (state) => {
        state.loading = true;
      })
      .addCase(getSubCompany.rejected, (state, { payload }) => {
        state.loading = false;
        // state.error = payload.error;
      })
      .addCase(getSubCompany.fulfilled, (state, { payload }) => {
        state.loading = false;
        // state.successMessage = payload.message;
        state.total = payload.total;
        state.subCompanyList = payload.data;
      })
      .addCase(getCompanyById.pending, (state) => {
        state.loading = true;
      })
      .addCase(getCompanyById.rejected, (state, { payload }) => {
        state.loading = false;
        // state.errorMessage = payload.error;
      })
      .addCase(getCompanyById.fulfilled, (state, { payload }) => {
        state.loading = false;
        // state.successMessage = payload.message;
        state.selectedCompany = payload.data;
      })
      .addCase(addCompany.pending, (state) => {
        state.loading = true;
      })
      .addCase(addCompany.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload.error;
      })
      .addCase(addCompany.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.successMessage = payload.message;
        state.selectedCompany = payload.data;
      })
      .addCase(addRetailer.pending, (state) => {
        state.loading = true;
      })
      .addCase(addRetailer.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload.error;
      })
      .addCase(addRetailer.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.successMessage = payload.message;
        state.selectedCompany = payload.data;
      })
      .addCase(updateCompany.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateCompany.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload.error;
      })
      .addCase(updateCompany.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.successMessage = payload.message;
        state.selectedCompany = payload.data;
      });
    // [getProducts.fulfilled]: (state, action) => {
    //   state.productList = action.payload.data;
    //   state.tableData.total = action.payload.total;
    //   state.loading = false;
    // },
  },
});

export const { messageClear } = companySlice.actions;
export default companySlice.reducer;
