import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Api from "services/api/Api";
import httpErrorHandler from "services/api/httpErrorHandler";

export const getBrands = createAsyncThunk(
  "brand/getBrands",
  async (_, { rejectWithValue, fulfillWithValue }) => {
    try {
      const { data } = await Api.get(`/brands`);
      console.log("getBrands", data.data);
      return fulfillWithValue(data);
    } catch (error) {
      // console.log(error.response.data);
      const errMsg = httpErrorHandler(error);
      return rejectWithValue(errMsg);
    }
  }
);

export const createBrand = createAsyncThunk(
  "brand/createBrand",
  async (info, { rejectWithValue, fulfillWithValue }) => {
    console.log("info", info);
    try {
      const { data } = await Api.post(`/brands`, info);
      console.log("createBrand", data);
      return fulfillWithValue(data);
    } catch (error) {
      console.log("createBrand error", error.response.data);
      const errMsg = httpErrorHandler(error);
      console.log("createBrand errMsg", errMsg);
      return rejectWithValue(error?.response?.data ?? JSON.stringify(error));
    }
  }
);

export const updateBrand = createAsyncThunk(
  "brand/updateBrand",
  async (info, { rejectWithValue, fulfillWithValue }) => {
    console.log("info", info);
    const { id } = info;
    try {
      const { data } = await Api.patch(`/brands/${id}`, info);
      console.log("updateBrand", data);
      return fulfillWithValue(data);
    } catch (error) {
      console.log("updateBrand error", error.response.data);
      const errMsg = httpErrorHandler(error);
      console.log("updateBrand errMsg", errMsg);
      return rejectWithValue(error?.response?.data ?? JSON.stringify(error));
    }
  }
);

const brandSlice = createSlice({
  name: "brand",
  initialState: {
    successMessage: "",
    errorMessage: "",
    loader: false,
    brands: [],
    selectedBrand: {},
  },
  reducers: {
    messageClear: (state, _) => {
      state.errorMessage = "";
      state.successMessage = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getBrands.pending, (state, { payload }) => {
        state.loader = true;
      })
      .addCase(getBrands.rejected, (state, { payload }) => {
        state.loader = false;
        state.errorMessage = payload.error;
      })
      .addCase(getBrands.fulfilled, (state, { payload }) => {
        state.loader = false;
        // state.successMessage = payload.message;
        state.brands = payload.data;
      })
      .addCase(createBrand.pending, (state) => {
        state.loading = true;
      })
      .addCase(createBrand.rejected, (state, { payload }) => {
        state.loading = false;
        state.errorMessage = payload.error;
      })
      .addCase(createBrand.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.successMessage = payload.message;
        state.selectedBrand = payload.data;
      })
      .addCase(updateBrand.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateBrand.rejected, (state, { payload }) => {
        console.log("payload", payload);
        state.loading = false;
        state.errorMessage = payload.error;
      })
      .addCase(updateBrand.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.successMessage = payload.message;
        state.selectedBrand = payload.data;
      });
  },
});

export const { messageClear } = brandSlice.actions;
export default brandSlice.reducer;
