import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { jwtDecode } from "jwt-decode";
import httpErrorHandler from "services/api/httpErrorHandler";
import Api from "services/api/Api";

export const signup = createAsyncThunk("auth/signup", async (info, { rejectWithValue, fulfillWithValue }) => {
  try {
    console.log("signup");
    const { data } = await Api.post("auth/signup", info);
    // localStorage.setItem('accessToken',data.token)
    console.log("signup", data);
    return fulfillWithValue(data);
  } catch (error) {
    console.log(error?.response?.data?.message || error.toString());
    // const errMsg = httpErrorHandler(error);
    // console.log(errMsg);
    return rejectWithValue(error);
  }
});

export const login = createAsyncThunk("auth/login", async (info, { rejectWithValue, fulfillWithValue }) => {
  console.log(info);
  try {
    const { data } = await Api.post("/auth/login", info, { withCedentials: true });
    console.log("login", data);

    localStorage.setItem("accessToken", data.token);
    return data;
  } catch (error) {
    const errMsg = httpErrorHandler(error);
    // console.log('errMsg', errMsg);
    // console.log(error.response.data);
    // return error;
    return rejectWithValue(errMsg);
  }
});

export const logout = createAsyncThunk("auth/logout", async (info, { rejectWithValue, fulfillWithValue }) => {
  console.log("logout");
  try {
    const { data } = await Api.post("/auth/logout");

    localStorage.removeItem("accessToken");
    return fulfillWithValue(data);
  } catch (error) {
    const errMsg = httpErrorHandler(error);
    // console.log('errMsg', errMsg);
    // console.log(error.response.data);
    // return error;
    return rejectWithValue(errMsg);
  }
});

export const getRole = (token) => {
  if (token) {
    const decodeToken = jwtDecode(token);
    const expireTime = new Date(decodeToken.exp * 1000);
    if (new Date() > expireTime) {
      console.log("Token expired");
      localStorage.removeItem("accessToken");
      return "";
    } else {
      // console.log("Token valid", decodeToken);
      return decodeToken.role;
    }
  } else {
    return "";
  }
};

export const verifyEmail = createAsyncThunk("auth/verifyEmail", async (info, { rejectWithValue, fulfillWithValue }) => {
  try {
    console.log(info);
    const { data } = await Api.post("auth/verifyEmail", info);
    // localStorage.setItem('accessToken',data.token)

    console.log(data);
    return fulfillWithValue(data);
  } catch (error) {
    console.log("rejected:", error.response.data.message);
    console.log("rejected handler:", httpErrorHandler(error));
    return rejectWithValue(error.response.data);
  }
});

export const initialState = {
  isLoading: false,
  successMessage: "",
  errorMessage: "",
  signupSuccessMessage: "",
  signupErrorMessage: "",
  logoutSuccessMessage: "",
  logoutErrorMessage: "",
  verifyErrorMessage: "",
  verifySuccessMessage: "",
  userInfo: {},
  // signedIn: false,
  role: getRole(localStorage.getItem("accessToken")),
  token: localStorage.getItem("accessToken") || "",
};

const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    messageClear: (state, _) => {
      state.errorMessage = "";
      state.successMessage = "";
      state.verifyErrorMessage = "";
      state.verifySuccessMessage = "";
      state.signupSuccessMessage = "";
      state.signupErrorMessage = "";
      state.logoutSuccessMessage = "";
      state.logoutErrorMessage = "";
    },
    setUser: (_, action) => action.payload,
    userLoggedOut: () => initialState,
    onSignOutSuccess: (state) => {
      state.userInfo = {};
      // state.signedIn = false;
      state.role = "";
      state.token = "";
    },
  },
  extraReducers(builder) {
    builder
      .addCase(login.pending, (state, { payload }) => {
        state.isLoading = true;
      })
      .addCase(login.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.userInfo = payload.data;
        state.token = payload.token;
        // state.signedIn = true;
        state.role = getRole(payload.token);
        state.successMessage = payload.message + ` Login as ${payload.data.fullName}`;
      })
      .addCase(login.rejected, (state, { payload }) => {
        console.log("rejected", payload);
        state.isLoading = false;
        state.errorMessage = payload;
      })
      .addCase(logout.pending, (state, { payload }) => {
        state.isLoading = true;
      })
      .addCase(logout.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.userInfo = {};
        state.token = "";
        // state.signedIn = false;
        state.logoutSuccessMessage = payload.message;
      })
      .addCase(logout.rejected, (state, { payload }) => {
        console.log("rejected", payload);
        state.isLoading = false;
        state.logoutErrorMessage = payload;
      })
      .addCase(signup.pending, (state, { payload }) => {
        state.loader = true;
      })
      .addCase(signup.rejected, (state, { payload }) => {
        state.loader = false;
        state.signupErrorMessage = payload?.response?.data?.message || payload.toString();
      })
      .addCase(signup.fulfilled, (state, { payload }) => {
        state.loader = false;
        state.signupSuccessMessage = payload.message;
        // state.userInfo = payload.user;
      })
      .addCase(verifyEmail.pending, (state, { payload }) => {
        state.loader = true;
      })
      .addCase(verifyEmail.rejected, (state, { payload }) => {
        state.loader = false;
        console.log("verifyEmail rejected", payload);
        state.verifyErrorMessage = payload?.message || "Verification failed";
      })
      .addCase(verifyEmail.fulfilled, (state, { payload }) => {
        state.loader = false;
        console.log("verifyEmail success", payload);
        // state.successMessage = "verify success";
        state.verifySuccessMessage = payload.message;
      });
  },
});

export const { messageClear, setUser, onSignOutSuccess } = usersSlice.actions;
export default usersSlice.reducer;
